<template>
  <div id="profile-page">
    <!-- My Details -->

    <vx-card>
      <div class="profileDetail flex flex-1 flex-col lg:flex-row items-center justify-between space-y-3 w-full">
        <div class="flex items-center space-x-4">
          <div v-if="activeUserInfo.photoURL">
              <img
                key="onlineImg"
                :src="activeUserInfo.photoURL"
                alt="user-img"
                class="w-16 mx-auto mb-2"
              />
            </div>
            <div>
              <p class="text-xl text-center lg:text-left">My Profile</p>
              <p class="text-sm text-center lg:text-left font-light">Manage account details</p>
            </div>
        </div>
        <div class="flex items-center space-x-3 w-full lg:w-4/12">
          
           <ProfileChangePassword />
            <vs-button
              v-if="edit"
              icon-pack="feather"
              icon="icon-x"
              color="danger"
              type="filled"
              class="flex-1"
              @click="cancelEdit()"
              >Cancel Edit</vs-button
            >
            <vs-button
              v-else
              icon-pack="feather"
              icon="icon-edit"
              color="primary"
              type="filled"
              class="flex-1"
              @click="editProfile()"
              >Edit Profile</vs-button
            >
            
        </div>
      </div>
    </vx-card>


    <!-- alert popup -->
    <vs-popup class="holamundo" title="Profile" :active.sync="popupActive">
      <p>
        Profile update temporarily saved. Request for profile update is
        currently under review. Profile will be updated accordingly after
        review.
      </p>
    </vs-popup>

    <!-- Edit Profile Detail -->
    <vx-card v-if="edit" title="Edit Profile Detail" class="mt-base">
      <ProfileEdit
        :userData="userDetail"
        @cancelEdit="cancelEdit"
        @showAlert="showAlert"
      />
    </vx-card>

    <!-- Profile Detail -->
    <vx-card v-else title="Profile Details" class="mt-base">
      <ProfileDetail />
    </vx-card>
  </div>
</template>

<script>
import ProfileDetail from "@/views/components/profile/ProfileDetail";
import ProfileEdit from "@/views/components/profile/ProfileEdit";
import ProfileChangePassword from "@/views/components/profileChangePassword";

export default {
  components: {
    ProfileDetail,
    ProfileEdit,
    ProfileChangePassword
  },
  data() {
    return {
      edit: false,
      userDetail: {},
      popupActive: false
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    editProfile() {
      this.edit = true;
    },
    cancelEdit() {
      this.edit = false;
    },
    showAlert() {
      this.popupActive = true;
    }
  },
  created() {
    this.userDetail = this.$store.state.AppActiveUser;
  }
};
</script>


<style>
  .profileDetail .vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
      padding: 0.75rem 0.5rem;
  }
</style>